import { RequestResponse } from 'v2/application/common/helpers'
import { HttpClient } from 'v2/application/common/protocols/http'
import { error, success } from 'v2/domain/common/utils'
import { UpgradeLicense } from 'v2/domain/modules'
import { UpgradeLicenses } from 'v2/domain/usecases'

export class RemoteUpgradeLicense implements UpgradeLicenses 
{
    constructor (private readonly url: string, private readonly httpClient: HttpClient) {};

    async add ({ ...params }: UpgradeLicense.AddParams): Promise<any> 
    {
        const httpResponse = await this.httpClient.request(
        {
            method: 'post',
            url: this.url,
            body: { ...params },
        });

        const dataOrError = RequestResponse.handle(httpResponse)

        if (dataOrError.isError()) 
            return error(dataOrError.value);
    
        return success(dataOrError.value.response);
    };
};
