export const ServicesTypes = {
  EXAMPLES: {
    LOAD_EXAMPLE_LIST: Symbol('LoadExampleList'),
  },

  CLIENT: {
    LOAD_CLIENT_LIST: Symbol('LoadClientList'),
  },
  PROJECT: {
    LOAD_PROJECT_LIST: Symbol('LoadProjectList'),
  },
  QUESTIONARY: {
    LOAD_QUESTIONARY: Symbol('LoadQuestionary'),
    LOAD_QUESTIONARY_LIST: Symbol('LoadQuestionaryList'),
    LOAD_QUESTIONARY_DETAIL: Symbol('LoadQuestionaryDetail'),
  },
  ROOM: {
    DELETE_ROOM: Symbol('remoteDeleteRoom'),
    LOAD_ROOM_MEDIAS: Symbol('LoadRoomMedias'),
  },
  QUESTION: {
    LOAD_QUESTION_LIST: Symbol('LoadQuestionList'),
  },

  ANSWER: {
    ADD_ANSWER: Symbol('AddAnswer'),
    ADD_ANSWER_V2: Symbol('AddAnswerV2'),
    UPDATE_ANSWER: Symbol('UpdateAnswer'),
  },
  USER_GENERAL_EQUIPMENT: {
    LOAD_USER_AND_GENERAL_EQUIPMENTS: Symbol('LoadUserAndGeneralEquipment'),
  },
  USER_EQUIPMENT: {
    LOAD_USER_EQUIPMENT_BY_CATEGORY: Symbol('LoadUserEquipmentByCategory'),
  },
  PROPOSED_EQUIPMENT: {
    UPDATE_EQUIPMENT: Symbol('UpdateProposedEquipment'),
    ADD_NEW_EQUIPMENT: Symbol('RemoteAddProposedEquipment'),
    ADD_PROPOSED_EQUIPMENT: Symbol('AddProposedEquipmentWithCurrent'),
    LOAD: Symbol('LoadProposedEquipments'),
    LOAD_PROPOSED_AND_CURRENT: Symbol('LoadProposedAndCurrentEquipments'),
    EVALUATE: Symbol('RemoteEvaluateEquipmentSugestion'),
    LOAD_RECOMENDATIONS: Symbol('RemoteLoadRecomendations'),
    REPLACE: Symbol('RemoteReplaceEquipment'),
    SUGGESTIONS_LIST: Symbol('RemoteSuggestionsList'),
    LOAD_EQUIPMENT: Symbol('LoadEquipment'),
    DELETE_PROPOSED_EQUIPMENT: Symbol('AddProposedEquipmentWithCurrent'),
    LOAD_CURRENT_EQUIPMENT: Symbol('LoadCurrentEquipment'),
  },
  CURRENT_EQUIPMENTS: {
    UPDATE_CURRENT_EQUIPMENT: Symbol('UpdateCurrentEquipment'),
    REPLACE_EQUIPMENTS: Symbol('RemoteReplaceEquipments'),
  },
  REPORT: {
    EXPORT: Symbol('LoadReportExport'),
    SEND_REPORTS: Symbol('SendReports'),
    PIX_ORDER_REPORT: Symbol('RemoteLoadQrCode'),
    CREDIT_CARD_REPORT: Symbol('CreditCardReport'),
    REMAINING: Symbol('TenantRemainingReports'),
  },
  CHARTS: {
    CONSUME_HISTORY: Symbol('ConsumeHistory'),
    REPLACED_EQUIPMENTS: Symbol('ReplacedEquipments'),
    CONSUME_DISTRIBUTION: Symbol('ConsumeDistribution'),
    CONSUME_DISTRIBUTION_COMPARISION: Symbol('ConsumeDistributionComparision'),
    PAYBACK: Symbol('Payback'),
    ANNUAL_SAVINGS: Symbol('AnnualSavings'),
    FIRST_ENERGY_CONSUMPTION_HISTORY: Symbol('FirstEnergyConsumptionHistory'),
  },
  MEDIA: {
    UPDATE_MEDIA: Symbol('RemoteUpdateMediaService'),
  },
  LICENSE: {
    LOAD_LICENSE_ADMIN_LIST: Symbol('LoadLicenseAdminList'),
    LOAD_LICENSE_LIST: Symbol('LoadLicenseList'),
    ADD_CARD: Symbol('LoadAddCard'),
    SEND_CARD_TO_BACKEND: Symbol('SendCardToBackend'),
    LOAD_CARD: Symbol('LoadCard'),
    LOAD_CURRENT_LICENSE: Symbol('LoadCurrentLicense'),
  },
  COUPON: {
    LOAD_COUPON_LIST: Symbol('LoadCouponList'),
    COUPON_LIST: Symbol('LoadCouponList'),
    COUPON_UPDATE: Symbol('RemoteUpdateCouponDetails'),
  },
  CARDS: {
    TOGGLE_CLASSIFICATION: Symbol('ToggleCardClassification'),
  },
  PAYMENTS: {
    RETRY: Symbol('RetryPayment'),
    SEND_INVOICE: Symbol('SendInvoice'),
  },
};
