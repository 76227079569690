import { makeStyles, createStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      padding: theme.spacing(2, 2, 3),
      alignItems: 'center',
    },
    adornedEnd: {
      padding: 0,
      paddingRight: theme.spacing(1),
    },
    clearButton: {
      padding: theme.spacing(0.5),
      '&:hover': {
        background: theme.palette.action.hover,
      },
    },
    searchButton: {
      position: 'relative',
      width: '100%',
      maxWidth: '70px',
      minWidth: '0',
      [theme.breakpoints.up('sm')]: {
        width: 'inherit',
        minWidth: '88px',
      },
    },
  }),
)
