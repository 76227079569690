import { InvalidFieldError } from 'v2/validation/errors'
import { FieldValidation } from 'v2/validation/protocols'

export class MinLegthValidation implements FieldValidation {
  constructor (readonly field: string, private readonly minLength: number) {}

  validate (input: FieldValidation.Params): Error {
    return input[this.field]?.length < this.minLength
      ? new InvalidFieldError(
          JSON.stringify({
            error: 'VALIDATION.MIN_LENGTH',
            option: { min: this.minLength },
          }),
        )
      : null
  }
}
