import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { colors } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(1, 0),
    },
    label: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 400,
      fontFamily: 'Poppins',
    },
    icon: {
      marginLeft: theme.spacing(1),
    },
    optionalLabel: {
      color: colors.grey[500],
      fontSize: theme.typography.pxToRem(12),
    },
  }),
);
