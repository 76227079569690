import { getDependencies } from 'v2/ioc/helpers/get-dependencies'
import { ServicesTypes } from 'v2/ioc/types'

import { apiSlice } from 'v2/store/features/api/slice/api-slice'
import { queryAdapter } from 'v2/store/helpers'

import { LoadLicenseAdminList } from 'v2/domain/usecases'

const [LoadLicenseAdminListService] = getDependencies<
  [LoadLicenseAdminList]
>([
  ServicesTypes.LICENSE.LOAD_LICENSE_ADMIN_LIST,
])

export const licenseApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    loadLicenseAdminList: builder.query<LoadLicenseAdminList.Model, LoadLicenseAdminList.Params>({
      queryFn: async (params) =>
        queryAdapter(LoadLicenseAdminListService.load(params))
    }),
  })
})
