import { FieldValidation } from 'v2/validation/protocols';
import {
  RequiredFieldValidation,
  EmailValidation,
  MinLegthValidation,
  CompareFieldsValidation,
  MaxLengthValidation,
  PasswordStrengthValidation,
  LatitudeValidation,
  LongitudeValidation,
  PositiveNumberValidation,
} from 'v2/validation/validators';
import { DecimalValidation } from '../decimal/decimal-validation';
import { GreaterThanValidation } from '../greather-than/greather-than-validation';
import { IntegerValidation } from '../integer/integer-validation';

export class ValidationBuilder {
  private constructor(
    private readonly fieldName: string,
    private readonly validations: FieldValidation[],
  ) {}

  static field(fieldName: string): ValidationBuilder {
    return new ValidationBuilder(fieldName, []);
  }

  required(stringCasted?: boolean): ValidationBuilder {
    this.validations.push(
      new RequiredFieldValidation(this.fieldName, stringCasted),
    );
    return this;
  }

  email(): ValidationBuilder {
    this.validations.push(new EmailValidation(this.fieldName));
    return this;
  }

  min(length: number): ValidationBuilder {
    this.validations.push(new MinLegthValidation(this.fieldName, length));
    return this;
  }

  greaterThan(number: number, message?: string): ValidationBuilder {
    this.validations.push(
      new GreaterThanValidation(this.fieldName, number, message),
    );
    return this;
  }

  max(length: number): ValidationBuilder {
    this.validations.push(new MaxLengthValidation(this.fieldName, length));
    return this;
  }

  positiveNumber(fieldName?: string): ValidationBuilder {
    this.validations.push(
      new PositiveNumberValidation(this.fieldName, fieldName),
    );
    return this;
  }

  sameAs(fieldToCompare: string): ValidationBuilder {
    this.validations.push(
      new CompareFieldsValidation(this.fieldName, fieldToCompare),
    );
    return this;
  }

  password(): ValidationBuilder {
    this.validations.push(new PasswordStrengthValidation(this.fieldName));
    return this;
  }

  longitude(): ValidationBuilder {
    this.validations.push(new LongitudeValidation(this.fieldName));
    return this;
  }

  latitude(): ValidationBuilder {
    this.validations.push(new LatitudeValidation(this.fieldName));
    return this;
  }

  integer(): ValidationBuilder {
    this.validations.push(new IntegerValidation(this.fieldName));
    return this;
  }

  decimal(): ValidationBuilder {
    this.validations.push(new DecimalValidation(this.fieldName));
    return this;
  }

  build(): FieldValidation[] {
    return this.validations;
  }
}
