import { CouponFormTypes, CouponFormFields } from 'v2/presentation/common/types'

export const EDIT_COUPON_FORM = {
  ...Object.values(CouponFormFields).reduce((accumulator, current) => {
    return {
      ...accumulator,
      [current]: '',
    }
  }, {} as CouponFormTypes),
}

export const COUPONS_LIST_COLUMNS = [
  {
    field: 'code',
    name: 'CODE',
  },
  {
    field: 'currentDiscountType',
    name: 'COUPONS.DISCOUNT_PERCENTAGE',
  },
  {
    field: 'redemptions',
    name: 'COUPONS.REDEMPTIONS_LIMIT',
  },
  {
    field: 'expiredDate',
    name: 'COUPONS.EXPIRED_DATE',
  },
  {
    field: 'isActive',
    name: 'STATUS',
  },
]
