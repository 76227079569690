import React from 'react';
import { NoteAddOutlined } from '@material-ui/icons';
import {
  Table as MuiTable,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Box,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useStyles } from './table-styles';
import {
  AddIcon,
  AnswerIcon,
  DeleteIcon,
  EditIcon,
  ToggleIcon,
  ViewDetailIcon,
} from '../icons';
import Menu from '../menu/menu';

type ColumnDefinition = {
  field: string;
  name: string;
};

type RowDefinition = {
  [fieldName: string]: string | number | React.ReactNode;
};

type Props = {
  columns: ColumnDefinition[];
  rows: RowDefinition[];
  onDetail?: (id: string | number) => void;
  onEdit?: (id: string | number) => void;
  onDisable?: (row: RowDefinition) => void;
  onEnable?: (row: RowDefinition) => void;
  onDescription?: (row: RowDefinition) => void;
  onQuestionary?: (id: string | number) => void;
  onProposedEquipment?: (id: string | number) => void;
  onGeneralNotes?: (row: RowDefinition) => void;
  onExports?: (row: RowDefinition) => void;
  onSendReports?: (row: RowDefinition) => void;
  onAcquireReports?: (row: RowDefinition) => void;
  onSendEmail?: (row: RowDefinition) => void;
  onDelete?: (row: RowDefinition) => void;
  loading?: boolean;
};

const Table: React.FC<Props> = ({
  columns,
  rows,
  onDetail,
  onEdit,
  onQuestionary,
  onProposedEquipment,
  onGeneralNotes,
  onDisable,
  onSendEmail,
  onEnable,
  onDescription,
  onExports,
  onAcquireReports,
  onDelete,
  loading,
  onSendReports
}) => {
  const columnsKeys = columns.map(column => column.field);
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const isFirst = (index: number): boolean => index === 0;
  const isLast = (index: number, length: number): boolean => index === length;

  const handleAlignment = (
    index: number,
    length: number,
  ): 'left' | 'center' | 'right' => {
    if (isFirst(index)) return 'left';
    if (isLast(index, length) && !hasActions) return 'right';
    return 'center';
  };

  const hasActions =
    !!onDetail ||
    !!onEdit ||
    !!onDelete ||
    !!onDisable ||
    !!onEnable ||
    !!onSendEmail ||
    !!onDescription;

  const filterActionsByOptions = (
    actions: Array<{
      id: string;
      text: string;
      icon: React.ReactNode;
      onClick: () => void;
    }>,
  ): Array<{
    id: string;
    text: string;
    icon: React.ReactNode;
    onClick: () => void;
  }> =>
    actions.filter(
      action =>
        (action.id === 'detail' && onDetail) ||
        (action.id === 'edit' && onEdit) ||
        (action.id === 'questionary' && onQuestionary) ||
        (action.id === 'proposed-equipment' && onProposedEquipment) ||
        (action.id === 'exports' && onExports) ||
        (action.id === 'sendReports' && onSendReports) ||
        (action.id === 'acquireReports' && onAcquireReports) ||
        (action.id === 'general_notes' && onGeneralNotes) ||
        (action.id === 'delete' && onDelete) ||
        (action.id === 'disable' && onDisable) ||
        (action.id === 'enable' && onEnable) ||
        (action.id === 'sendEmail' && onSendEmail) ||
        (action.id === 'description' && onDescription),
    );

  const valueOrDefaultValue = (
    value: string | number,
    defaultValue: string,
  ): string | number => (!!value ? value : defaultValue);

  const countActionColumn = hasActions ? 1 : 0;

  return (
    <TableContainer>
      <MuiTable className={classes.root}>
        <TableHead>
          <TableRow>
            {columns?.map((column, index) => (
              <TableCell
                key={column.field}
                className={classes.head}
                align={handleAlignment(index, columns.length - 1)}>
                <span>{formatMessage({ id: column.name })}</span>
              </TableCell>
            ))}
            {hasActions && (
              <TableCell className={classes.head} align="center" />
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading && (
            <TableRow>
              <TableCell
                align="center"
                colSpan={columns?.length + countActionColumn}
                className={classes.loading}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          )}
          {!loading && rows.length === 0 && (
            <TableRow>
              <TableCell
                align="center"
                colSpan={columns?.length + countActionColumn}
                className={classes.noDataAvailable}>
                {formatMessage({
                  id: 'MESSAGES.NO_DATA_AVAILABLE',
                })}
              </TableCell>
            </TableRow>
          )}
          {!loading &&
            rows?.map(row => (
              <TableRow key={row.id as string}>
                {columnsKeys.map((key, index) => {
                  if (React.isValidElement(row[key])) {
                    if (row.fileExtension === 'note') {
                      return (
                        <TableCell
                          key={key}
                          className={classes.cell}
                          align={handleAlignment(
                            index,
                            columnsKeys.length - 1,
                          )}>
                          <Box display="flex" alignItems="center">
                            <NoteAddOutlined
                              fontSize="large"
                              style={{ height: 40, width: 40 }}
                              color="primary"
                            />
                          </Box>
                        </TableCell>
                      );
                    }
                    return (
                      <TableCell
                        key={key}
                        className={classes.cell}
                        align={handleAlignment(index, columnsKeys.length - 1)}>
                        {row[key]}
                      </TableCell>
                    );
                  }
                  return (
                    <TableCell
                      key={key}
                      className={classes.cell}
                      align={handleAlignment(index, columnsKeys.length - 1)}>
                      {valueOrDefaultValue(row[key] as string | number, '-')}
                    </TableCell>
                  );
                })}
                {hasActions && (
                  <TableCell align="right">
                    <Menu
                      items={filterActionsByOptions([
                        {
                          id: 'detail',
                          text: formatMessage({
                            id: 'DETAILS',
                          }),
                          icon: <ViewDetailIcon />,
                          onClick: () => onDetail(row.id as string),
                        },
                        {
                          id: 'general_notes',
                          text: formatMessage({
                            id: 'GENERAL_NOTES',
                          }),
                          icon: <AnswerIcon />,
                          onClick: () => onGeneralNotes(row),
                        },
                        {
                          id: 'edit',
                          text: formatMessage({
                            id: 'EDIT',
                          }),
                          icon: <EditIcon />,
                          onClick: () => onEdit(row.id as string),
                        },
                        {
                          id: 'disable',
                          text: formatMessage({
                            id: 'ENABLE_OR_DISABLE',
                          }),
                          icon: <ToggleIcon />,
                          onClick: () => onDisable(row),
                        },
                        {
                          id: 'enable',
                          text: formatMessage({
                            id: 'ENABLE',
                          }),
                          icon: <AddIcon />,
                          onClick: () => onDisable(row),
                        },
                        {
                          id: 'description',
                          text: formatMessage({
                            id: 'DESCRIPTION',
                          }),
                          icon: <EditIcon />,
                          onClick: () => onDescription(row),
                        },

                        {
                          id: 'questionary',
                          text: formatMessage({
                            id: 'QUESTIONNAIRE.ANSWER',
                          }),
                          icon: <AnswerIcon />,
                          onClick: () => onQuestionary(row.id as string),
                        },
                        {
                          id: 'proposed-equipment',
                          text: formatMessage({
                            id: 'Proposed Equipments',
                          }),
                          icon: <AnswerIcon />,
                          onClick: () => onProposedEquipment(row.id as string),
                        },
                        {
                          id: 'sendReports',
                          text: formatMessage({
                            id: 'EXPORT',
                          }),
                          icon: <AnswerIcon />,
                          onClick: () => onSendReports(row),
                        },
                        {
                          id: 'exports',
                          text: formatMessage({
                            id: 'EXPORT',
                          }),
                          icon: <AnswerIcon />,
                          onClick: () => onExports(row),
                        },
                        {
                          id: 'acquireReports',
                          text: formatMessage({
                            id: 'ACQUIRE_REPORTS',
                          }),
                          icon: <AnswerIcon />,
                          onClick: () => onAcquireReports(row),
                        },

                        {
                          id: 'delete',
                          text: formatMessage({
                            id: 'DELETE',
                          }),
                          icon: <DeleteIcon />,
                          onClick: () => onDelete(row),
                        },
                        {
                          id: 'sendEmail',
                          text: formatMessage({
                            id: 'SEND_INVOICE',
                          }),
                          icon: <AnswerIcon />,
                          onClick: () => onSendEmail(row),
                        },
                      ])}
                    />
                  </TableCell>
                )}
              </TableRow>
            ))}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
};

export default React.memo(Table);
