import { licenseApi } from 'v2/store/features/license';
import { siteApi } from 'v2/store/features/site';
import {
  proposedEquipmentsApi,
  userEquipmentsApi,
} from 'v2/store/features/equipments';

import { answerApi } from 'v2/store/features/answer';
import { chartPaybackApi } from 'v2/store/features/charts';

import { roomApi } from 'v2/store/features/room';
import { questionariesApi } from 'v2/store/features/questionaries';

export const { useLazyLoadLicenseAdminListQuery } = licenseApi;

export const {
  useLazyLoadProposedEquipmentsQuery,
  useLazyLoadSuggestionsListQuery,
  useLazyLoadReplaceEquipmentsQuery,
  useAddProposedEquipmentMutation,
  useLazyLoadProposedAndCurrentEquipmentsQuery,
  useLazyLoadEquipmentQuery,
  useLazyLoadCurrentEquipmentsQuery,
  useDeleteProposedEquipmentsMutation,
  useUpdateProposedEquipmentsMutation,
  useUpdateCurrentEquipmentsMutation,
} = proposedEquipmentsApi;

export const { useAddAnswerMutation } = answerApi;

export const {
  useDeleteRoomServiceMutation,
  useLazyLoadRoomMediaQuery,
} = roomApi;
export const { useLazyLoadChartPaybackQuery } = chartPaybackApi;

export const { useLazyLoadUserEquipmentByCategoryQuery } = userEquipmentsApi;

export const { useLazyLoadQuestionariesQuery } = questionariesApi;

export const { useSendReportsMutation } = siteApi;
