import React, { useMemo, useRef } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePickerProps,
  KeyboardDatePicker,
  DatePicker,
} from '@material-ui/pickers';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import clsx from 'clsx';
import { ptBR } from 'date-fns/locale';
import { useForm } from 'v2/presentation/hooks';
import { useStyles } from './date-input-styles';
import { useIntl } from 'react-intl';

type Props = Omit<KeyboardDatePickerProps, 'value' | 'onChange'> & {
  label: string;
  optional?: boolean;
  onChangeValue?: Function;
};

const DateInput: React.FC<Props> = ({
  label,
  name,
  className,
  optional,
  onChangeValue,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const inputName = name as keyof typeof errors;
  const classes = useStyles();
  const { form, handleChange, errors, setImperativeError } = useForm();
  const dateRef = useRef<HTMLInputElement>(null);

  const handleDateError = (error: string, _: ParsableDate): void => {
    if (error && !errors[inputName]) setImperativeError(inputName, error);
  };

  const handleDateChange = (value: any): void => {
    handleChange({ value, name: inputName });
    onChangeValue && onChangeValue(value);
  };

  const translatedLabel = useMemo(() => formatMessage({ id: label }), [
    formatMessage,
    label,
  ]);

  const optionalLabel = useMemo(() => formatMessage({ id: 'OPTIONAL' }), [
    formatMessage,
  ]);

  const value = useMemo(
    () => (form[inputName] ? new Date(form[inputName]) : null),
    [form, inputName],
  );

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
      <div className={classes.container}>
        <label className={classes.label}>
          {translatedLabel}{' '}
          {optional && (
            <span className={classes.optionalLabel}>{optionalLabel}</span>
          )}
        </label>
        <DatePicker
          autoOk
          fullWidth
          name={inputName}
          variant="inline"
          format="dd/MM/yyyy"
          margin="none"
          color="primary"
          PopoverProps={{
            PaperProps: {
              square: true,
              classes: {
                root: classes.dialog,
              },
            },
          }}
          InputProps={{
            className,
          }}
          InputAdornmentProps={{
            classes: {
              root: clsx(classes.icon, {
                [classes.iconError]: !!errors[inputName],
              }),
            },
          }}
          inputVariant="outlined"
          inputProps={{
            'data-testid': inputName,
            ref: dateRef,
          }}
          value={value}
          onChange={handleDateChange}
          onError={handleDateError}
          error={Boolean(errors[inputName])}
          helperText={errors[inputName]}
          FormHelperTextProps={{ title: inputName }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          {...props}
        />
        {/* <KeyboardDatePicker
                    autoOk
                    fullWidth
                    name={inputName}
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="none"
                    color="primary"
                    PopoverProps={{
                        PaperProps: {
                            square: true,
                            classes: {
                                root: classes.dialog,
                            },
                        },
                    }}
                    InputProps={{
                        className,
                    }}
                    InputAdornmentProps={{
                        classes: {
                            root: clsx(classes.icon, {
                                [classes.iconError]: !!errors[inputName],
                            }),
                        },
                    }}
                    inputVariant="outlined"
                    inputProps={{
                        'data-testid': inputName,
                        ref: dateRef,
                    }}
                    value={value}
                    onChange={handleDateChange}
                    onError={handleDateError}
                    error={Boolean(errors[inputName])}
                    helperText={errors[inputName]}
                    FormHelperTextProps={{ title: inputName }}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    {...props}
                /> */}
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default DateInput;
