import { RequestResponse } from 'v2/application/common/helpers';
import { HttpClient } from 'v2/application/common/protocols/http';
import { Response } from 'v2/domain/common/types';
import { error, success } from 'v2/domain/common/utils';
import { LoadCurve } from 'v2/domain/modules';
import { UpdateLoadCurve } from 'v2/domain/usecases';

export class RemoteUpdateLoadCurve implements UpdateLoadCurve {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient,
    private urlId: string,
  ) {}

  async update({
    ...params
  }: LoadCurve.UpdateLoadCurveParams): Promise<Response<void>> {
    const httpResponse = await this.httpClient.request({
      method: 'put',
      url: `${this.url}?id=${this.urlId}`,
      body: { ...params },
    });

    const dataOrError = RequestResponse.handle(httpResponse);

    if (dataOrError.isError()) {
      return error(dataOrError.value);
    }

    return success(null);
  }
}
