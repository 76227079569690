import { RequestResponse } from 'v2/application/common/helpers'
import { HttpClient } from 'v2/application/common/protocols/http'
import { error, success } from 'v2/domain/common/utils'
import { LoadCurve } from 'v2/domain/modules'
import { CreateLoadCurve } from 'v2/domain/usecases'
import { nullToEmptyString } from 'v2/application/common/helpers'

type CreateParams = { id: string }

export class RemoteCreateLoadCurve
  implements CreateLoadCurve {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient,
  ) {}

  private formatResponse ({ id }: CreateParams): CreateParams 
  {
    const formattedResponse: CreateParams = { id };
    return nullToEmptyString<CreateParams>(formattedResponse)
  }

  async add ({ ...params }: LoadCurve.CreateLoadCurveParams): Promise<any> 
  {
    const httpResponse = await this.httpClient.request({
      method: 'post',
      url: this.url,
      body: { ...params },
    })

    const dataOrError = RequestResponse.handle(httpResponse)

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    const formattedResponse = this.formatResponse(dataOrError.value.response);
    return success(formattedResponse);
  }
}
