import {
  InputProps,
  TextField,
  Tooltip,
  TextFieldProps,
} from '@material-ui/core';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect, useMemo, useState } from 'react';
import InputMask from 'react-input-mask';
import { useIntl } from 'react-intl';
import { useForm } from 'v2/presentation/hooks/use-form';
import { useStyles } from './text-input-styles';
import InfoIcon from '@material-ui/icons/InfoRounded';

type Props = TextFieldProps & {
  name?: string;
  label?: string;
  value?: string | number;
  min?: string;
  error?: string;
  placeholder?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  maxInputValue?: number;
  optional?: boolean;
  inputProps?: InputProps;
  maxLength?: string;
  mask?: string;
  customMask?: (value: string) => string;
  maskPlaceholder?: string;
  tooltipText?: string;
  maxDecimalLength?: number;
};

const TextInput: React.FC<Props> = ({
  name,
  maxLength,
  label,
  error,
  value,
  placeholder,
  min,
  onChange,
  maxInputValue,
  optional = false,
  disabled,
  type,
  mask,
  customMask,
  maxDecimalLength,
  maskPlaceholder,
  inputProps = {},
  tooltipText,
  ...props
}) => {
  const classes = useStyles();
  const { handleChange, form, errors } = useForm();
  const inputName = name as keyof typeof errors;
  const { formatMessage } = useIntl();

  const handleDecimalValue = (value: string): string => {
    let onlyNumbers = value.replace(/[^0-9]/g, '');

    const firstDigits = onlyNumbers.substring(0, onlyNumbers.length - 2);
    const lastDigits = onlyNumbers.substring(
      onlyNumbers.length - 2,
      onlyNumbers.length,
    );
    return `${firstDigits || 0}.${Number(lastDigits).toFixed(
      maxDecimalLength,
    ) || '00'}`;
  };

  const handleOnlyNumbers = (value: string): string => {
    return value.replace(/[^0-9]/g, '');
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (type === 'decimal') {
      event.target.value = handleDecimalValue(event.target.value);
    }
    if (type === 'autocomplete') return;
    if (maxInputValue) {
      event.target.value = handleMaxInputValue(event.target.value);
    }
    if (type === 'onlyNumbers') {
      event.target.value = handleOnlyNumbers(event.target.value);
    }

    maskValue(event);
  };

  const maskValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { value, name } = event.target;

    const maskedValue = customMask?.(value);

    if (maskedValue) value = maskedValue;

    if (onChange) onChange(event);

    if (handleChange) {
      return handleChange({
        value: maskedValue ? maskedValue : value,
        name: name,
      });
    }
  };

  const handleMaxInputValue = (value: string): string => {
    if (!value.split('.')[1]) return value;
    if (+value > +maxInputValue) {
      return maxInputValue.toString();
    }
    return value;
  };

  const translatedLabel = label
    ? useMemo(() => formatMessage({ id: label }), [formatMessage, label])
    : useMemo(() => formatMessage({ id: 'OPTIONAL' }), [formatMessage]);

  const inputValue = useMemo(() => value || form[inputName], [
    form,
    inputName,
    value,
  ]);

  return (
    <div className={classes.container}>
      {label && (
        <label className={classes.label}>
          {translatedLabel}
          {tooltipText && (
            <Tooltip title={tooltipText} arrow placement="right-end">
              <InfoIcon className={classes.icon} />
            </Tooltip>
          )}
        </label>
      )}
      {!mask ? (
        <TextField
          {...props}
          variant="outlined"
          fullWidth
          onChange={handleInputChange}
          name={name}
          value={inputValue}
          placeholder={placeholder}
          error={!!errors}
          helperText={errors[inputName] || ''}
          disabled={disabled}
          type={type}
          InputProps={{
            type: props.InputProps?.type || type,
            ref: props.InputProps?.ref,
            endAdornment: props.InputProps?.endAdornment,
          }}
          inputProps={{
            ...inputProps,
            min: min,
            max: maxInputValue,
            maxLength,
          }}
        />
      ) : (
        <InputMask
          mask={mask}
          maskPlaceholder={maskPlaceholder}
          value={inputValue}
          onChange={handleInputChange}>
          {(maskedProps: any) => (
            <TextField
              {...maskedProps}
              {...props}
              variant="outlined"
              fullWidth
              name={name}
              placeholder={placeholder}
              error={!!errors}
              helperText={errors[inputName] || ''}
              disabled={disabled}
              type={type}
              InputProps={{
                type: props.InputProps?.type || type,
                ref: props.InputProps?.ref,
                endAdornment: props.InputProps?.endAdornment,
                maxLength,
              }}
              inputProps={{
                ...inputProps,
                min: min,
                max: maxInputValue,
                maxLength,
              }}
            />
          )}
        </InputMask>
      )}
    </div>
  );
};

export default React.memo(TextInput);
