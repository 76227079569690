import { ContainerModule } from "inversify";
import { RemoteRetryPayment, RemoteSendInvoice } from "v2/application/services";
import { RetryPayment, SendInvoice } from "v2/domain/usecases";
import { ServicesTypes } from "v2/ioc/types";

export const PaymentsModule = new ContainerModule(bind => {
  bind<RetryPayment>(ServicesTypes.PAYMENTS.RETRY).to(
    RemoteRetryPayment
  )

  bind<SendInvoice>(ServicesTypes.PAYMENTS.SEND_INVOICE).to(
    RemoteSendInvoice
  )
})