import { inject } from 'inversify'
import { RequestResponse } from 'v2/application/common/helpers'
import type { HttpClient } from 'v2/application/common/protocols/http'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { RemoteCouponModel } from 'v2/application/models'
import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { Coupon } from 'v2/domain/modules'
import { AddCoupon } from 'v2/domain/usecases'
import { ApiTypes, InfraTypes } from 'v2/ioc/types'

export class RemoteAddCoupon implements AddCoupon {
  constructor (
    @inject(ApiTypes.COUPON.ADD_COUPON)
    private readonly url: string,
    @inject(InfraTypes.AUTHORIZED_HTTP_CLIENT)
    private readonly httpClient: HttpClient<void[]>,
  ) {}

  async add (params: Coupon.AddParams): Promise<Response<void>> {
    const httpResponse = await this.httpClient.request({
      method: 'post',
      url: this.url,
      body: {
        coupon: {
          ...params
        },
      },
    })

    const dataOrError = RequestResponse.handle(httpResponse)

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    return success(null)
  }
}
