import * as React from 'react';
import { SubDomainRouter, SubDomainRoute } from './SubDomainRouter';
import AdminRouter from './admin/AdminRouter';
import TenantRouter from './tenant/TenantRouter';

function Routes() {
  return (
    <SubDomainRouter>
      <SubDomainRoute name="" isFallback={true} component={TenantRouter} />
      <SubDomainRoute name="admin" component={AdminRouter} />
      <SubDomainRoute name="drenergia-admin-dev" component={AdminRouter} />
      <SubDomainRoute name="drenergia-admin-test" component={AdminRouter} />
      <SubDomainRoute name="drenergia-admin-prod" component={AdminRouter} />
    </SubDomainRouter>
  );
}

export { Routes };
