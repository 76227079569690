import {
  Box,
  IconButton,
  TextField,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Button } from '..'
import { ClearIcon, SearchIcon } from '../icons'
import { useStyles } from './search-bar-styles'

type Props = {
  onSearch: (keyword: string) => void
}

const SearchBar: React.FC<Props> = ({ onSearch }) => {
  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))
  const { formatMessage } = useIntl()
  const [hasSearched, setHasSearched] = useState(false)
  const [value, setValue] = useState('')
  const classes = useStyles()

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    const { value } = event.target
    setValue(value)
  }

  const handleSearch = (): void => {
    if (!value) return null
    setHasSearched(true)
    onSearch(value)
  }

  const handleEnterPress = (
    event: React.KeyboardEvent<HTMLDivElement>,
  ): void => {
    event.key === 'Enter' && handleSearch()
  }

  const handleClear = (): void => {
    if (!hasSearched) {
      return setValue('')
    }
    setValue('')
    setHasSearched(false)
    onSearch('')
  }

  return (
    <div className={classes.root}>
      <TextField
        value={value}
        onChange={handleChange}
        onKeyPress={handleEnterPress}
        placeholder={formatMessage({ id: 'SEARCH.PLACEHOLDER' })}
        variant='outlined'
        InputProps={{
          classes: {
            adornedEnd: classes.adornedEnd,
          },
          endAdornment: (
            <IconButton
              className={classes.clearButton}
              onClick={handleClear}
              disabled={!value && !hasSearched}>
              <ClearIcon fontSize='small' />
            </IconButton>
          ),
        }}
      />
      <Box ml={1} width='20%' position='relative'>
        <Button
          className={classes.searchButton}
          variant='contained'
          color='primary'
          startIcon={isSmUp && <SearchIcon />}
          onClick={handleSearch}>
          {isSmUp ? (
            formatMessage({ id: 'SEARCH' })
          ) : (
            <SearchIcon fontSize='default' />
          )}
        </Button>
      </Box>
    </div>
  )
}

export default SearchBar
