import { ContainerModule } from 'inversify'
import { makeApiUrl } from 'v2/ioc/helpers'
import { ApiTypes } from 'v2/ioc/types'

export const ReportsApiModule = new ContainerModule(bind => {
  bind<string>(ApiTypes.REPORT.PIX_ORDER_REPORT).toDynamicValue(() =>
    makeApiUrl('reports/createPixOrder'),
  )

  bind<string>(ApiTypes.REPORT.CREDIT_CARD_ORDER_REPORT).toDynamicValue(() =>
    makeApiUrl('reports/createCardOrder'),
  )
})
