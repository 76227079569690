export const LICENSES_LIST_COLUMNS = [
    {
        field: 'name',
        name: 'LICENSE.NAME'
    },
    {
        field: 'tenantCount',
        name: 'LICENSE.ACTIVE_USERS',
    },
    {
        field: 'updatedDate',
        name: 'LICENSE.UPDATED_AT'
    },
    {
        field: 'isActive',
        name: 'LICENSE.STATUS'
    }
]