import {
  AnswerModule,
  ChartsModule,
  ClientModule,
  EquipmentModule,
  ProjectModule,
  ProposedEquipmentModule,
  QuestionaryModule,
  QuestionModule,
  SiteModule,
  CouponModule,
  ReportsModule,
  LicensesModule,
  PaymentsModule,
  MediasModule,
  RoomModule,
} from './modules';

export const ApplicationModule = [
  QuestionModule,
  QuestionaryModule,
  AnswerModule,
  ProposedEquipmentModule,
  EquipmentModule,
  SiteModule,
  ClientModule,
  ProjectModule,
  ChartsModule,
  CouponModule,
  ReportsModule,
  LicensesModule,
  PaymentsModule,
  MediasModule,
  RoomModule,
];
