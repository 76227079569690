import { RequestResponse } from 'v2/application/common/helpers'
import { HttpClient } from 'v2/application/common/protocols/http'
import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { Coupon } from 'v2/domain/modules'
import { ActivateOrDeactivateCoupon } from 'v2/domain/usecases'

export class RemoteActivateOrDeactivateCoupon implements ActivateOrDeactivateCoupon {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient,
  ) {}

  async update (params: Coupon.ActivateOrDeactivateParams): Promise<Response<void>> {
    const httpResponse = await this.httpClient.request({
      method: 'put',
      url: this.url + `?id=${params.id}`,
    })

    const dataOrError = RequestResponse.handle(httpResponse)

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    return success(null)
  }
}
