import { ContainerModule } from 'inversify'
import { makeApiUrl } from 'v2/ioc/helpers'
import { ApiTypes } from 'v2/ioc/types'

export const LicenseApiModule = new ContainerModule(bind => {
  bind<string>(ApiTypes.LICENSE.ADD_CARD).toDynamicValue(() =>
    makeApiUrl(''),
  )
  bind<string>(ApiTypes.LICENSE.LOAD_LICENSE_LIST).toDynamicValue(() =>
    makeApiUrl('/plans/list'),
  )

  bind<string>(ApiTypes.LICENSE.LOAD_LICENSE_ADMIN_LIST).toDynamicValue(() =>
    makeApiUrl('/plans/admin-list'),
  )

  bind<string>(ApiTypes.LICENSE.LOAD_LICENSE_ADMIN_DETAILS).toDynamicValue(() =>
    makeApiUrl('/plans/details'),
  )
  bind<string>(ApiTypes.LICENSE.SUBSCRIPTION).toDynamicValue(() =>
    makeApiUrl('/subscriptions/create'),
  )
  bind<string>(ApiTypes.LICENSE.LOAD_CURRENT_LICENSE).toDynamicValue(() =>
    makeApiUrl('/plans/current'),
  )
  bind<string>(ApiTypes.CARDS.TOGGLE_CARD_CLASSIFICATION).toDynamicValue(() =>
    makeApiUrl('cards/update-main'),
  )
})
