export const REPORTS_FIELDS = [
  {
    id: '1',
    name: 'REPORTS.ADVANCED',
  },
  {
    id: '2',
    name: 'REPORTS.BASIC',
  },
]
