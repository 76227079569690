export enum AddCardFormFields {
  CARD_NUMBER = 'cardNumber',
  VALIDITY = 'validity',
  CVV = 'cvv',
  NAME_ON_CARD = 'nameOnCard'
}

export type AddCardFormTypes = {
  [key in AddCardFormFields]: string
}

export type AddCardDetailFormTypes = AddCardFormTypes