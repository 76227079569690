import { ContainerModule } from 'inversify'
import { makeApiUrl } from 'v2/ioc/helpers'
import { ApiTypes } from 'v2/ioc/types'

export const PaymentsApiModule = new ContainerModule(bind => {
  bind<string>(ApiTypes.PAYMENTS.RETRY).toDynamicValue(() =>
    makeApiUrl('payments/retry'),
  )

  bind<string>(ApiTypes.PAYMENTS.SEND_INVOICE).toDynamicValue(() =>
    makeApiUrl('invoices/admin-download'),
  )
})
