import React from 'react';
import {
  Dialog as MuiDialog,
  DialogTitle,
  DialogProps,
  DialogContent,
  DialogActions,
  CircularProgress,
  IconButton,
} from '@material-ui/core';
import { CloseIcon } from 'v2/presentation/components/icons';
import { Button } from 'v2/presentation/components';

import { useStyles } from './dialog-styles';
import { useIntl } from 'react-intl';
import clsx from 'clsx';

type Props = DialogProps & {
  loading?: boolean;
  onClose: () => void;
  onSubmit?: () => void;
  onClick?: () => void;
  onRemove?: () => void;
  onDisable?: () => void;
  onEnable?: () => void;
  float?: boolean;
  formId?: string;
  disableText?: string;
  submit?: {
    isSubmitting: boolean;
  };
  remove?: {
    isRemoving: boolean;
  };
  disable?: {
    isDisabling: boolean;
  };
  enable?: {
    isEnabling: boolean;
  };
  submitTitle?: string;
  disableSubmit?: boolean;
};

const Dialog: React.FC<Props> = ({
  open,
  loading,
  onClose,
  disableText,
  onSubmit,
  onClick,
  onRemove,
  onDisable,
  onEnable,
  title,
  children,
  formId,
  submit,
  maxWidth = 'md',
  remove,
  submitTitle,
  disable,
  enable,
  disableSubmit,
  float,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  return (
    <MuiDialog
      {...props}
      scroll="paper"
      maxWidth={maxWidth}
      fullWidth
      open={open}
      onClose={onClose}>
      {title && (
        <DialogTitle>
          <div className={classes.title}>
            {title}
            {onClose && (
              <IconButton aria-label="close" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            )}
          </div>
        </DialogTitle>
      )}
      <DialogContent dividers className={classes.contentContainer}>
        {loading && (
          <div className={classes.loading}>
            <CircularProgress />
          </div>
        )}
        <div className={classes.content}>{!loading && children}</div>
      </DialogContent>

      <DialogActions
        className={clsx([float ? classes.actionsEnd : classes.actions])}>
        <Button variant="outlined" onClick={onClose}>
          {formatMessage({ id: 'CANCEL' })}
        </Button>
        {(submit || onSubmit) && (
          <Button
            type="submit"
            disabled={loading || disableSubmit}
            variant="contained"
            color="primary"
            onSubmit={onSubmit}
            onClick={!onSubmit && onClick}
            loading={submit?.isSubmitting}
            form={formId}>
            {submitTitle ? submitTitle : formatMessage({ id: 'SAVE' })}
          </Button>
        )}
        {(remove || onRemove) && (
          <Button
            disabled={loading}
            variant="contained"
            color="primary"
            onClick={onRemove}
            loading={remove?.isRemoving}
            form={formId}>
            {formatMessage({ id: 'DELETE' })}
          </Button>
        )}
        {(disable || onDisable) && (
          <Button
            disabled={loading}
            variant="contained"
            color="primary"
            onClick={onDisable}
            loading={disable?.isDisabling}
            form={formId}>
            {formatMessage({ id: 'DISABLE' })}
          </Button>
        )}
        {(enable || onEnable) && (
          <Button
            disabled={loading}
            variant="contained"
            color="primary"
            onClick={onEnable}
            loading={enable?.isEnabling}
            form={formId}>
            {formatMessage({ id: 'ENABLE' })}
          </Button>
        )}
      </DialogActions>
    </MuiDialog>
  );
};

export default React.memo(Dialog);
