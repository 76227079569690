import { inject, injectable } from 'inversify'
import {
  RequestResponse,
} from 'v2/application/common/helpers'
import type { HttpClient } from 'v2/application/common/protocols/http'
import { RemoteLicenseModel } from 'v2/application/models'
import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { License } from 'v2/domain/modules'
import { LoadLicenseList } from 'v2/domain/usecases'
import { ApiTypes, ConstantTypes, InfraTypes } from 'v2/ioc/types'

@injectable()
export class RemoteLoadLicenseList implements LoadLicenseList {
  constructor(
    @inject(ApiTypes.LICENSE.LOAD_LICENSE_LIST)
    private readonly url: string,
    @inject(InfraTypes.AUTHORIZED_HTTP_CLIENT)
    private readonly httpClient: HttpClient<RemoteLicenseModel[]>,
    @inject(ConstantTypes.AUTHENTICATED_USER_TENANT_ID)
    private readonly tenantId?: string
  ) { }

  async load(): Promise<Response<License.Model>> {
    let url = this.url;

    const httpResponse = await this.httpClient.request({
      method: 'get',
      url: `${url}`,
    })

    const dataOrError = RequestResponse.handle<RemoteLicenseModel[]>(
      httpResponse,
    )

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    const formattedResponse = this.formatResponse(dataOrError.value.response)
    return success(formattedResponse)
  }

  private formatResponse(data: RemoteLicenseModel[] | any): License.Model {
    const newData: RemoteLicenseModel[] = data.rows ? data.rows : data

    const formattedResponse = newData.map((license) => ({
      id: license.id,
      name: license.name,
      amount: license.amount,
      planReportTypes: license.PlanReportTypes,
      popular: license.popular,
      price: license.price,
      plan: license.plan
    }))

    return formattedResponse
  }
}
