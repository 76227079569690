import { Coupon } from 'v2/domain/modules'
import { EDIT_COUPON_FORM } from '../constants'
import { CouponDetailsFormtype } from '../types'

export const mapCouponDetailsForm = (
  coupon: Coupon.EditModel,
  loading: boolean,
): Omit<
  CouponDetailsFormtype,
  'code' | 'tenantIds' | 'isActive'
> => {
  if (!coupon || loading) return { ...EDIT_COUPON_FORM }

  return {
    planIds: coupon.CouponPlans.map(plan => plan.planId),
    durationMonths: Number(coupon.durationMonths),
    expiredDate: coupon.expiredDate,
    redemptions: coupon.redemptions.toString(),
    maxRedemptions: Number(coupon.maxRedemptions),
    couponTypeId: coupon.discountPercentage ? '8ef8cd31-0dcf-4029-b794-60b23031db02' : 'd4d3bc1a-2915-489a-bb53-e18f86c67449',
    fixedDiscount: coupon.discountAmount ? coupon.discountAmount : null,
    percentageDiscount: coupon.discountPercentage ? coupon.discountPercentage : null
  }
}
