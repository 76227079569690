import { RequestResponse } from 'v2/application/common/helpers'
import { HttpClient } from 'v2/application/common/protocols/http'
import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { Card } from 'v2/domain/modules'
import { DeleteCard } from 'v2/domain/usecases'

export class RemoteDeleteCard implements DeleteCard 
{
    constructor (private readonly url: string, private readonly httpClient: HttpClient) {};

    async delete (id: string): Promise<Response<Card.DeleteParams>> 
    {
        const httpResponse = await this.httpClient.request(
        {
            method: 'delete',
            url: this.url,
            body: { card: { id } },
        });
      
        const dataOrError = RequestResponse.handle(httpResponse)
      
        if (dataOrError.isError()) 
            return error(dataOrError.value);
        
        return success(null);
    };
};
