import { ContainerModule } from "inversify";
import { RemoteLoadCouponList, RemoteUpdateCouponDetails } from "v2/application/services";
import { LoadCouponList, UpdateCouponDetails } from "v2/domain/usecases";
import { ServicesTypes } from "v2/ioc/types";

export const CouponModule = new ContainerModule(bind => {
  bind<LoadCouponList>(ServicesTypes.COUPON.LOAD_COUPON_LIST).to(
    RemoteLoadCouponList
  )
  bind<UpdateCouponDetails>(ServicesTypes.COUPON.COUPON_UPDATE).to(
    RemoteUpdateCouponDetails
  )
})