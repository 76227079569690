/* eslint-disable @typescript-eslint/no-unused-vars */

import { inject, injectable } from 'inversify'
import {
    RequestResponse,
} from 'v2/application/common/helpers'
import type { HttpClient } from 'v2/application/common/protocols/http'
import { RemoteLoadPaymentsListModel } from 'v2/application/models'
import { ListParams, Paginated, Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { Payments } from 'v2/domain/modules'
import { LoadPaymentsList } from 'v2/domain/usecases/payments/load-paymants-list'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ApiTypes, InfraTypes } from 'v2/ioc/types'

@injectable()
export class RemoteLoadPaymentsList implements LoadPaymentsList {
    constructor(
        @inject(ApiTypes.PAYMENTS.LOAD_PAYMENTS_LIST)
        private readonly url: string,
        @inject(InfraTypes.AUTHORIZED_HTTP_CLIENT)
        private readonly httpClient: HttpClient<Paginated<RemoteLoadPaymentsListModel>>,
    ) { }

    async load({
        page = 0,
        pageSize = 10,
        keyword = '',
    }: ListParams): Promise<Response<Paginated<Payments.Model>>> {
        const httpResponse = await this.httpClient.request({
            method: 'get',
            url: `${this.url}?page=${page}&pageSize=${pageSize}&keywords=${keyword}`,
        })

        const dataOrError = RequestResponse.handle<Paginated<RemoteLoadPaymentsListModel>>(
            httpResponse,
        )

        if (dataOrError.isError()) {
            return error(dataOrError.value)
        }

        const formattedResponse = this.formatResponse(dataOrError.value.response)
        return success(formattedResponse)
    }

    private formatResponse({
        rows,
        ...params
    }: Paginated<RemoteLoadPaymentsListModel>): Paginated<Payments.Model> {

        const formattedResponse = rows.map((payment) => ({
            id: payment.id,
            Tenant: payment.Tenant,
            Payments: payment.Payment,
            Coupon: payment.Coupon,
            finalAmount: payment.finalAmount,
            Plan: payment.Plan,
            InvoiceType: payment.InvoiceType,
            InvoiceStatus: payment.InvoiceStatus,
            product: payment.Plan ? payment.Plan : payment.ReportType
        }))

        return { ...params, rows: [...formattedResponse] }
    }
}
