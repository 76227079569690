import { ContainerModule } from "inversify";
import { RemoteToggleCardClassification, RemoteLoadLicenseAdminList } from "v2/application/services";
import { ToggleCardClassification, LoadLicenseAdminList } from "v2/domain/usecases";
import { ServicesTypes } from "v2/ioc/types";

export const LicensesModule = new ContainerModule(bind => {
  bind<ToggleCardClassification>(ServicesTypes.CARDS.TOGGLE_CLASSIFICATION).to(
    RemoteToggleCardClassification
  )
  bind<LoadLicenseAdminList>(ServicesTypes.LICENSE.LOAD_LICENSE_ADMIN_LIST).to(RemoteLoadLicenseAdminList)
})