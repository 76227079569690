import { inject, injectable } from 'inversify'
import {
  RequestResponse,
} from 'v2/application/common/helpers'
import type { HttpClient } from 'v2/application/common/protocols/http'
import { RemoteCouponModel } from 'v2/application/models'
import { ListParams, Paginated, Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { Coupon } from 'v2/domain/modules'
import { LoadCouponList } from 'v2/domain/usecases'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ApiTypes, ConstantTypes, InfraTypes } from 'v2/ioc/types'

@injectable()
export class RemoteLoadCouponList implements LoadCouponList {
  constructor(
    @inject(ApiTypes.COUPON.LOAD_COUPON_LIST)
    private readonly url: string,
    @inject(InfraTypes.AUTHORIZED_HTTP_CLIENT)
    private readonly httpClient: HttpClient<Paginated<RemoteCouponModel>>,
  ) { }

  async load({
    page = 0,
    pageSize = 10,
    keyword = '',
  }: ListParams): Promise<Response<Paginated<Coupon.Model>>> {
    const httpResponse = await this.httpClient.request({
      method: 'get',
      url: `${this.url}?page=${page}&pageSize=${pageSize}&keywords=${keyword}`,
    })

    const dataOrError = RequestResponse.handle<Paginated<RemoteCouponModel>>(
      httpResponse,
    )

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    const formattedResponse = this.formatResponse(dataOrError.value.response)
    return success(formattedResponse)
  }

  private formatResponse({
    rows,
    ...params
  }: Paginated<RemoteCouponModel>): Paginated<Coupon.Model> {

    const formattedResponse = rows.map((coupon) => ({
      id: coupon.id,
      name: coupon.name,
      code: coupon.code,
      discountPercentage: coupon.discountPercentage,
      discountAmount: coupon.discountAmount,
      maxRedemptions: coupon.maxRedemptions,
      redemptions: coupon.redemptions,
      expiredDate: coupon.expiredDate,
      isActive: coupon.isActive
    }))

    return { ...params, rows: [...formattedResponse] }
  }
}
