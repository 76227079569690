import { ContainerModule } from 'inversify'
import { makeApiUrl } from 'v2/ioc/helpers'
import { ApiTypes } from 'v2/ioc/types'

export const CouponApiModule = new ContainerModule(bind => {
  bind<string>(ApiTypes.COUPON.LOAD_COUPON_LIST).toDynamicValue(() =>
    makeApiUrl('coupons/list'),
  )
  bind<string>(ApiTypes.COUPON.COUPON_DETAILS).toDynamicValue(() =>
    makeApiUrl('coupons/details'),
  )
  bind<string>(ApiTypes.COUPON.UPDATE_COUPON).toDynamicValue(() =>
    makeApiUrl('coupons/update'),
  )
})
