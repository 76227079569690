import { inject, injectable } from 'inversify'
import {
  RequestResponse,
} from 'v2/application/common/helpers'
import type { HttpClient } from 'v2/application/common/protocols/http'
import { RemoteLicenseAdminListModel } from 'v2/application/models/remote-license-admin-list-model'
import { ListParams, Paginated, Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { License } from 'v2/domain/modules'
import { LoadLicenseAdminList } from 'v2/domain/usecases'
import { ApiTypes, InfraTypes } from 'v2/ioc/types'

@injectable()
export class RemoteLoadLicenseAdminList implements LoadLicenseAdminList {
  constructor(
    @inject(ApiTypes.LICENSE.LOAD_LICENSE_ADMIN_LIST)
    private readonly url: string,
    @inject(InfraTypes.AUTHORIZED_HTTP_CLIENT)
    private readonly httpClient: HttpClient<Paginated<RemoteLicenseAdminListModel>>
  ) { }

  async load({
    page = 0,
    pageSize = 10,
    keyword = '',
  }: ListParams): Promise<Response<Paginated<License.AdminModel>>> {
    const httpResponse = await this.httpClient.request({
      method: 'get',
      url: `${this.url}?page=${page}&pageSize=${pageSize}&keywords=${keyword}`,
    })

    const dataOrError = RequestResponse.handle<Paginated<RemoteLicenseAdminListModel>>(
      httpResponse,
    )

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    const formattedResponse = this.formatResponse(dataOrError.value.response)
    return success(formattedResponse)
  }

  private formatResponse({
    rows,
    ...params
  }: Paginated<RemoteLicenseAdminListModel>): Paginated<License.AdminModel> {
    const formattedResponse = rows?.map((license) => ({
      id: license.id,
      name: license.name,
      isActive: license.isActive,
      tenantCount: license.tenantCount,
      updatedDate: license.updatedDate
    }))

    return { ...params, rows: [...formattedResponse] }
  }
}
