/* eslint-disable @typescript-eslint/no-unused-vars */
import { inject, injectable } from 'inversify'
import {
  RequestResponse,
} from 'v2/application/common/helpers'
import type { HttpClient } from 'v2/application/common/protocols/http'
import { RemoteCouponDetailsModel } from 'v2/application/models'
import { Response } from 'v2/domain/common/types'
import { error, success } from 'v2/domain/common/utils'
import { Coupon } from 'v2/domain/modules'
import { LoadCouponDetails, LoadCouponList } from 'v2/domain/usecases'
import { ApiTypes, ConstantTypes, InfraTypes } from 'v2/ioc/types'

@injectable()
export class RemoteLoadCouponDetails implements LoadCouponDetails {
  constructor(
    @inject(ApiTypes.COUPON.LOAD_COUPON_LIST)
    private readonly url: string,
    @inject(InfraTypes.AUTHORIZED_HTTP_CLIENT)
    private readonly httpClient: HttpClient<RemoteCouponDetailsModel>,
  ) { }

  async load(id: string): Promise<Response<Coupon.DetailsModel>> {

    const httpResponse = await this.httpClient.request({
      method: 'get',
      url: `${this.url}?id=${id}`
    })

    const dataOrError = RequestResponse.handle<RemoteCouponDetailsModel>(
      httpResponse,
    )

    if (dataOrError.isError()) {
      return error(dataOrError.value)
    }

    const formattedResponse = this.formatResponse(dataOrError.value.response)
    return success(formattedResponse)
  }

  private formatResponse(data: RemoteCouponDetailsModel): Coupon.DetailsModel {
    const format = {
      id: data.id,
      name: data.name,
      code: data.code,
      discountPercentage: data.discountPercentage,
      discountAmount: data.discountAmount,
      maxRedemptions: data.maxRedemptions,
      redemptions: data.redemptions,
      expiredDate: data.expiredDate,
      isActive: data.isActive,
      CouponPlans: data.CouponPlans,
      durationMonths: data.durationMonths,
      tenantAvailableCoupons: data.TenantAvailableCoupons,
    }

    const formattedResponse = [format];

    return formattedResponse
  }
}
