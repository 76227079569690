import { makeStyles, createStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 700,
    },
    head: {
      color: theme.palette.text.primary,
      textTransform: 'uppercase',
      fontSize: theme.typography.pxToRem(16),
      letterSpacing: theme.typography.pxToRem(2),
      borderBottom: '1px solid #ebedf2',
      fontFamily: 'Poppins, sans-serif',
    },
    cell: {
      color: theme.palette.text.secondary,
      fontSize: theme.typography.pxToRem(16),
      borderBottom: '1px solid #ebedf2',
      fontFamily: 'Poppins, sans-serif',
    },
    loading: {
      justifyContent: 'center',
      alignItems: 'center',
      height: '285px',
    },
    noDataAvailable: {
      justifyContent: 'center',
      alignItems: 'center',
      height: '285px',
    },
  }),
)
