import React from 'react';
import { Warning as MuiWarningIcon } from '@material-ui/icons';

type Props = {
  fontSize?: 'small' | 'inherit' | 'default' | 'large';
};

const WarningIcon: React.FC<Props> = ({ fontSize = 'small' }) => {
  return <MuiWarningIcon fontSize={fontSize} />;
};

export default WarningIcon;
